import request from "@/utils/request";

// gestion des facture
export function getInvoiceNo() {
  return request({
    url: "/invoices/next-numero",
    method: "get",
  });
}

export function newInvoice(data) {
  return request({
    url: "/invoices",
    method: "post",
    data,
  });
}

export function getInvoice(numero) {
  return request({
    url: `/invoices/${numero}`,
    method: "get",
  });
}

export function getBalanceContact(contactId) {
  return request({
    url: `/invoices/balance/contact/${contactId}`,
    method: "get",
  });
}

export function getAllInvoice(query) {
  return request({
    url: `/invoices/fetch/async`,
    method: "get",
    params: query,
  });
}

export function getAllInvoiceForContact(query, contactId) {
  return request({
    url: `/invoices/fetch/async/contact/${contactId}`,
    method: "get",
    params: query,
  });
}

export function getAllInvoiceForContactOpen(contactId) {
  return request({
    url: `/invoices/contact/opend/${contactId}`,
    method: "get",
  });
}

export function getAllInvoiceOrder(reference) {
  return request({
    url: `/invoices/order/${reference}`,
    method: "get",
  });
}

export function deletInvoice(numero) {
  return request({
    url: `/invoices/cancel/${numero}`,
    method: "DELETE",
  });
}

export function fetchStatistcs() {
  return request({
    url: `/invoices/statistics`,
    method: "get",
  });
}

// paiement des factures
export function getNextPaymentNO() {
  return request({
    url: "/payment-invoice/next-numero",
    method: "get",
  });
}

export function newPaymentInvoice(data) {
  return request({
    url: "/payment-invoice",
    method: "post",
    data,
  });
}

export function getPaymentInvoice(numero) {
  return request({
    url: `/payment-invoice/payment-no/${numero}`,
    method: "get",
  });
}
export function getPaymentForInvoice(id) {
  return request({
    url: `/payment-invoice/invoice/${id}`,
    method: "get",
  });
}

export function cancelPaymentInvoice(id) {
  return request({
    url: `/payment-invoice/cancel/${id}`,
    method: "delete",
  });
}

export function getAllPaymentInvoice(query) {
  return request({
    url: "/payment-invoice/fetch/async",
    method: "get",
    params: query,
  });
}

// gestion des commandes
export function getAllOrders(query) {
  return request({
    url: "/orders/fetch/async",
    method: "get",
    params: query,
  });
}

export function createOrder(data) {
  return request({
    url: "/orders",
    method: "post",
    data,
  });
}

export function getOrder(id) {
  return request({
    url: `/orders/${id}`,
    method: "get",
  });
}

export function cancelOrder(id) {
  return request({
    url: `/orders/cancel/${id}`,
    method: "delete",
  });
}

export function closeOrder(id) {
  return request({
    url: `/orders/close/${id}`,
    method: "put",
  });
}

export function getOrderForContact(contactId) {
  return request({
    url: `/orders/fetch/async/contact/${contactId}`,
    method: "get",
  });
}

export function getOrderNo() {
  return request({
    url: `/orders/next-numero`,
    method: "get",
  });
}

export function confirmeOrder(id) {
  return request({
    url: `/orders/confirmed/${id}`,
    method: "get",
  });
}

export function converToDelivery(data) {
  return request({
    url: "/orders/convert-to-delivery",
    method: "post",
    data,
  });
}

export function converOrderToInvoice(data) {
  return request({
    url: "/orders/convert-to-invoice",
    method: "post",
    data,
  });
}

// gestion des bons de livraison
export function createBonLivraison(data) {
  return request({
    url: "/delivery-from",
    method: "post",
    data,
  });
}

export function nextBonLivraison() {
  return request({
    url: "/delivery-from/next-numero",
    method: "get",
  });
}

export function getBonLivraison(id) {
  return request({
    url: `/delivery-from/${id}`,
    method: "get",
  });
}

export function getBonLivraisonByReference(reference) {
  return request({
    url: `/delivery-from/reference/${reference}`,
    method: "get",
  });
}

export function deleteBonLivraison(id) {
  return request({
    url: `/delivery-from/${id}`,
    method: "delete",
  });
}

export function confirmeBonLivraison(id) {
  return request({
    url: `/delivery-from/confirme/${id}`,
    method: "put",
  });
}

export function fetchBonLivraison(query) {
  return request({
    url: `/delivery-from/fetch/async`,
    method: "get",
    params: query,
  });
}

export function converToInvoice(data) {
  return request({
    url: "/delivery-from/convert-to-invoice",
    method: "post",
    data,
  });
}

// gestion des devis
export function createQuote(data) {
  return request({
    url: "/quotes",
    method: "post",
    data,
  });
}

export function nextQuotes() {
  return request({
    url: "/quotes/next-numero",
    method: "get",
  });
}

export function fetchQuotes(query) {
  return request({
    url: `/quotes/fetch/async`,
    method: "get",
    params: query,
  });
}

export function refusedQuote(id) {
  return request({
    url: `/quotes/refused/${id}`,
    method: "put",
  });
}

export function getQuote(id) {
  return request({
    url: `/quotes/${id}`,
    method: "get",
  });
}

export function getCountQuoteWait() {
  return request({
    url: `/quotes/count-quote-waiting`,
    method: "get",
  });
}

export function converQuoteToInvoice(data) {
  return request({
    url: "/quotes/convert-to-invoice",
    method: "post",
    data,
  });
}

export function converQuoteToOrder(data) {
  return request({
    url: "/quotes/convert-to-order",
    method: "post",
    data,
  });
}

// rapport de ventes

export function fetchRapportSales(query, type) {
  return request({
    url: `/rapport-sales/${type}`,
    method: "get",
    params: query,
  });
}

export function fetchRapportResume(query, type) {
  return request({
    url: `/rapport-sales/resume/${type}`,
    method: "get",
    params: query,
  });
}

export function fetchRapportCHART(query, type) {
  return request({
    url: `/rapport-sales/chart/${type}`,
    method: "get",
    params: query,
  });
}

export function fetchRapportCHARTSale(query) {
  return request({
    url: `/rapport-sales/chart-sales`,
    method: "get",
    params: query,
  });
}

export function fetchStatSales() {
  return request({
    url: `/rapport-sales/stat-sales`,
    method: "get",
  });
}
