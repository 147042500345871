<template>
  <div class="flex flex-col font-Inter px-10">
    <Loading v-if="componentLoanding" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate"
          >
            Bon de commande en Facture N° {{ invoice.invoice_no }}
          </h2>
        </div>
        <div>
          <el-button icon="el-icon-save" class="mr-2" plain>Annuler</el-button>
          <!--  <el-button
            type="primary"
            icon="el-icon-save"
            :loading="loading"
            @click="handleSaveInvoice"
            >Enregistrer</el-button
          > -->
          <el-dropdown @command="handleSaveInvoice">
            <el-button type="primary"> Enregistrer </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="true"
                >Enregistrer et diminuer le stock</el-dropdown-item
              >
              <el-dropdown-item command="false"
                >Enregistre et diminuer plutard</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="mb-10 overflow-y-auto">
      <div class="flex flex-col mx-auto bg-white p-5 border shadow">
        <el-form :model="invoice" :label-position="labelPosition">
          <div class="flex justify-between items-start">
            <div class="flex flex-col w-1/2">
              <h1
                class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate mb-5"
              >
                {{ customerInfo.organization_name }}
              </h1>
              <div
                v-loading="loadingCustomerInfo"
                class="flex flex-col bg-gray-100 w-2/3 p-3"
              >
                <div class="flex justify-between mb-2">
                  <span class="text-gray-500">N°</span>
                  <span>{{ customerInfo.contact_no }}</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span class="text-gray-500">Téléphone</span>
                  <span>{{ customerInfo.phone }}</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span class="text-gray-500">Email</span>
                  <span>{{ customerInfo.email }}</span>
                </div>
                <div class="flex justify-between">
                  <span class="text-gray-500">Adresse</span>
                  <span>{{ customerInfo.street }}</span>
                </div>
                <div class="flex justify-between">
                  <span class="text-gray-500">Correspondant</span>
                  <span
                    >{{ customerInfo.last_name }}
                    {{ customerInfo.first_name }}</span
                  >
                </div>
              </div>
              <!--  <el-form-item label="Adresse de la facturation">
                <el-input
                  class="w-2/3"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 3 }"
                  placeholder="Entrez quelque chose"
                  v-model="invoice.adresse"
                />
              </el-form-item> -->
            </div>
            <div class="flex flex-col justify-end items-end w-1/2">
              <div
                class="text-3xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
              >
                Montant: {{ totalTTC | moneyFilter }} {{user.currency_code}}
              </div>
              <el-form-item label="Date">
                <el-date-picker
                  v-model="invoice.date_invoice"
                  type="date"
                  placeholder="jj-mm-aaaa"
                  format="dd-MM-yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Réference">
                <el-input v-model="invoice.reference"> </el-input>
              </el-form-item>
              <div class="flex">
                <el-form-item label="Terme">
                  <el-select
                    v-model="invoice.terms"
                    @change="handleDueDay"
                    class="mr-3"
                  >
                    <el-option
                      v-for="item in duedays"
                      :key="item.id"
                      :label="item.name"
                      :value="item.days"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Date d'échéance">
                  <el-date-picker
                    v-model="invoice.deadline_invoice"
                    type="date"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <el-form-item>
                <el-checkbox v-model="invoice.send_mail"
                  >Envoyer un mail</el-checkbox
                >
              </el-form-item>
            </div>
          </div>
        </el-form>

        <div class="customer-white">
          <el-table
            :data="invoice.items"
            style="width: 100%"
            stripe
            :header-cell-style="{ background: '#6956e5', color: 'white' }"
          >
            <el-table-column prop="" label="#" width="60">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>

            <el-table-column
              prop="article_name"
              label="Désignation"
              min-width="250"
            >
              <template slot-scope="{ row }">
                <div class="flex justify-start items-center">
                  <div>{{ row.article_name }}</div>
                </div>
              </template>
            </el-table-column>
            <!--  <el-table-column
              prop="quantity"
              align="right"
              label="Qté Restant"
              width="150"
            >
              <template slot-scope="{ row }">
                <div class="input-transparent">
                  <div class="flex flex-col">
                    <span>{{ row.quantity - row.qty_invoiced }}</span>
                    <span
                      v-if="row.qty_invoiced > 0"
                      class="text-green-600 text-xs"
                      >Facturée :{{ row.qty_invoiced }}</span
                    >
                  </div>
                </div>
              </template>
            </el-table-column> -->
            <!-- <el-table-column
              prop="quantity"
              align="right"
              label="Qté Livrée"
              width="150"
            >
              <template slot-scope="{ row }">
                <div class="input-transparent">
                  <span>{{ row.quantity_delivred }}</span>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="quantity"
              align="right"
              label="QTY"
              width="160"
            >
              <template slot-scope="{ row }">
                {{ row.quantity }}
                <!--  <div class="input-transparent">
                  <el-input-number
                    size="small"
                    :controls="false"
                    v-model="row.quantity"
                    :min="1"
                    :max="row.quantity - row.qty_invoiced"
                  />
                </div> -->
              </template>
            </el-table-column>
            <el-table-column
              prop="price"
              align="right"
              label="Prix"
              width="180"
            >
              <!--  <template slot-scope="{ row }">
                <div class="input-transparent ml-5">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="row.price"
                  />
                </div>
              </template> -->
              <template slot-scope="{ row }">
                <span>{{ row.price | moneyFilter }} {{user.currency_code}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="price"
              align="right"
              label="Montant"
              width="180"
            >
              <template slot-scope="{ row }">
                <span>{{ (row.quantity * row.price) | moneyFilter }} {{user.currency_code}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex flex-col justify-end items-end mb-2 border-b my-8">
          <div class="text-lg">Total HT: {{ totalHT | moneyFilter }} {{user.currency_code}}</div>
          <div
            class="text-lg flex justify-start items-center pt-2 text-gray-500"
          >
            <span class="mr-4">Remise:</span>
            <el-input-number
              v-model="invoice.amount_discount"
              :controls="false"
            />
          </div>
          <div class="text-lg mt-2 text-gray-500">
            <span class="mr-4">Taxe:</span>
            <el-input-number v-model="invoice.amount_taxe" :controls="false" />
          </div>
          <div
            class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncatemt-2 my-2"
          >
            Total Net : {{ totalTTC | moneyFilter }} {{user.currency_code}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInvoiceNo, convOrderToInvoice, getOrder } from "@/api/sales";
import Loading from "@/components/Loanding/Full";
import { fetchDueDays } from "@/api/settings";
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import {mapGetters} from "vuex";
export default {
  name: "CreateInvoice",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { Loading },
  data() {
    return {
      labelPosition: "top",
      loading: false,
      dialogVisible: false,
      articles: [],
      componentLoanding: true,
      loadingCustomerInfo: false,
      customers: [],
      duedays: [],
      articleName: "",
      customerName: "",
      selectedArticle: "",
      customerInfo: {
        contact_id: "",
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        street: "",
        contact_no: "",
        organization_name: "",
      },
      terms: [
        {
          day: 15,
          label: " 15 jours",
        },
        {
          day: 30,
          label: " 30 jours",
        },
        {
          day: 45,
          label: " 45 jours",
        },
      ],
      loadingArticle: false,
      loadingCustomer: false,
      invoice: {
        contact_id: "",
        stock_movement: false,
        send_mail: false,
        deadline_invoice: "",
        amount_discount: 0,
        amount_taxe: 0,
        date_invoice: formatDate(new Date()),
        terms: "",
        invoice_no: "",
        shipping_charge: 0,
        currency :"",
        status: "OPEND",
        reference: "",
        items: [],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
    totalHT: function () {
      return this.invoice.items.reduce(
        (total, item) => total + item.quantity * item.price,
        0
      );
    },
    totalTTC: function () {
      return (
        this.totalHT * (1 + this.invoice.amount_taxe / 100) -
        this.invoice.amount_discount
      );
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    handleEdit(row) {
      row.edit = !row.edit;
    },
    handleDelete(row) {
      console.log(row);
    },
    handleCustomer(id) {
      this.loadingCustomerInfo = true;
      setTimeout(() => {
        const customer = this.customers.find((c) => c.contact_id === id);
        this.customerInfo = Object.assign({}, customer);
        this.loadingCustomerInfo = false;
      }, 200);
    },
    async fetchDueDays() {
      await fetchDueDays().then((res) => {
        this.duedays = res.data;
      });
    },

    addArticle(row) {
      this.invoice.items.push({
        article_id: row.articleId,
        article_no: row.sku,
        article_name: row.articleName,
        description: row.describe,
        price: row.priceSale,
        edit: false,
        quantity: 1,
      });
    },
    handleDueDay() {
      // console.log(this.invoice);
      const date = new Date();
      date.setTime(date.getTime() + 3600 * 1000 * 24 * this.invoice.terms);
      this.invoice.deadline_invoice = formatDate(date);
    },
    initData() {
      const id = this.$route.params && this.$route.params.id;
      this.componentLoanding = true;
      setTimeout(() => {
        this.fetchOrder(id);
        this.generateNo();
        this.fetchDueDays();
        this.componentLoanding = false;
      }, 1.5 * 1000);
    },
    async generateNo() {
      await getInvoiceNo().then((res) => {
        this.invoice.invoice_no = res.data.data;
        console.log(this.invoice.invoice_no);
      });
    },
    createData() {
      this.componentLoanding = true;
      const id = this.$route.params && this.$route.params.id;
      //   const term = this.duedays.find((el) => el.days === this.invoice.terms);
      convOrderToInvoice(id, this.invoice)
        .then((res) => {
          setTimeout(() => {
            this.componentLoanding = false;
            this.$router.push(`/sales/invoice/${res.data.invoice_id}`);
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchOrder(id) {
      await getOrder(id)
        .then((res) => {
          this.customerInfo = {
            contact_id: res.data.contact_id,
            email: res.data.contact_email,
            phone: res.data.contact_phone,
            first_name: res.data.contact_firstname,
            last_name: res.data.contact_lastname,
            street: res.data.contact_street,
            contact_no: res.data.contact_no,
            organization_name: res.data.contact_organizationname,
          };
          this.invoice.contact_id = res.data.contact_id;
          this.invoice.reference = res.data.order_no;
          this.invoice.currency = res.data.currency;
          // this.invoice.deadline_invoice = res.data.date_deadline;
          this.invoice.items = res.data.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSaveInvoice(command) {
      if (command) {
        this.invoice.stock_movement = true;
      } else {
        this.invoice.stock_movement = false;
      }

      this.createData();
    },
  },
};
</script>

<style lang="scss" scoped>
input:checked + label {
  border-color: black;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
