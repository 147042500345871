import request from "@/utils/request";

export function fetchTypesOrganization() {
  return request({
    url: "/type-organization",
    method: "get",
  });
}

export function fetchCountries() {
  return request({
    url: "/countries",
    method: "get",
  });
}

export function fetchCurrencies() {
  return request({
    url: "/currencies",
    method: "get",
  });
}

// due days

export function fetchDueDays() {
  return request({
    url: "/due-days",
    method: "get",
  });
}

export function getDueDays(id) {
  return request({
    url: `/due-days/${id}`,
    method: "get",
  });
}

export function deleteDueDays(id) {
  return request({
    url: `/due-days/${id}`,
    method: "delete",
  });
}

export function createDueDays(data) {
  return request({
    url: "/due-days",
    method: "post",
    data,
  });
}

export function updateDueDays(data, id) {
  return request({
    url: `/due-days/${id}`,
    method: "put",
    data,
  });
}

// settings sals
export function updateSettingSales(data) {
  return request({
    url: `/settings-sales`,
    method: "put",
    data,
  });
}
export function getSettingSales() {
  return request({
    url: `/settings-sales`,
    method: "get",
  });
}

// METHODE DE PAYMENT

export function fetchMethodPayment() {
  return request({
    url: "/payment-mode/async",
    method: "get",
  });
}

export function createMethodPayment(data) {
  return request({
    url: "/payment-mode",
    method: "post",
    data,
  });
}

export function updateMethodPayment(data, id) {
  return request({
    url: `/payment-mode/${id}`,
    method: "put",
    data,
  });
}

// numerotation des document
export function fetchNumeroDoc() {
  return request({
    url: `/numero-doc`,
    method: "get",
  });
}

export function updateNumeroDoc(data) {
  return request({
    url: `/numero-doc`,
    method: "put",
    data,
  });
}


// categorie


export function fetchCategories() {
  return request({
    url: "/categories",
    method: "get",
  });
}

export function createCategory(data) {
  return request({
    url: "/categories",
    method: "post",
    data,
  });
}

export function updateCategory(data, id) {
  return request({
    url: `/categories/${id}`,
    method: "put",
    data,
  });
}



// taxe


export function fetchTaxes() {
  return request({
    url: "/taxes",
    method: "get",
  });
}

export function createTaxes(data) {
  return request({
    url: "/taxes",
    method: "post",
    data,
  });
}

export function updateTaxes(data, id) {
  return request({
    url: `/taxes/${id}`,
    method: "put",
    data,
  });
}

